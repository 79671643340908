<template>
  <q-card
    @click="food.is_active ? openModal(food) : ''"
    v-if="food.image"
    flat
    class="product-item"
    :style="{
      backgroundColor: getBranchConfig?.html_color_palette.Surface1,
      borderRadius: `${getBranchConfig?.radius}px`,
    }"
  >
    <div class="food-info">
      <div style="height: 141px;">
        <div
          @click="food.is_active ? openModal(food) : ''"
          size="87px"
          style="width: 208px; height: 141px; "
          class="relative-position"
        >
          <img
            v-if="!noImg"
            style="width: 100%; height: 100%;"
            class="food-img"
            :src="food.image"
            alt=""
            @click="food.is_active ? openModal(food) : ''"
            :style="{ borderRadius: `${getBranchConfig?.radius}px` }"
          />
          <div v-if="categoryAvailability" class="learn-more">
            <q-icon
              class="icon-right"
              name="chevron_right"
              size="24px"
              :style="{ color: getBranchConfig.html_color_palette?.Accent }"
            ></q-icon>
          </div>
        </div>
        <div class="container-info-product">
          <h3
            class=" title-product "
            style="color: #082136; font-family: Prata; font-size: 19px; "
            :style="{ ...getStyles?.h3, color: getStyles.h3?.colorSecondary }"
          >
            {{ food.name }}
          </h3>

          <div class="food-item--footer">
            <p
              class="p-best-seller"
              v-if="food.is_active"
              :style="{ ...getStyles?.p, color: getStyles.p?.colorSecondary }"
            >
              $ {{ new Intl.NumberFormat("es-CO").format(food.total_price) }}
              <svg
                v-if="food.spicy === true"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9577 3.02625C10.8469 2.45632 10.5416 1.94259 10.0939 1.57296C9.64613 1.20333 9.08387 1.00079 8.50328 1C8.37068 1 8.2435 1.05268 8.14973 1.14645C8.05596 1.24021 8.00328 1.36739 8.00328 1.5C8.00328 1.63261 8.05596 1.75979 8.14973 1.85355C8.2435 1.94732 8.37068 2 8.50328 2C8.82091 2 9.13034 2.10083 9.38699 2.28795C9.64364 2.47508 9.83427 2.73884 9.93141 3.04125C8.97997 3.17974 8.11009 3.65588 7.48069 4.38271C6.85129 5.10954 6.50436 6.03853 6.50328 7C6.50328 9.92188 4.89391 11.875 1.72016 12.8125C1.50307 12.8758 1.31383 13.0108 1.18333 13.1954C1.05283 13.3801 0.988777 13.6036 1.00161 13.8293C1.01445 14.0551 1.10342 14.2698 1.25401 14.4385C1.4046 14.6072 1.60792 14.7199 1.83078 14.7581C2.77017 14.9205 3.72184 15.0014 4.67516 15C7.22391 15 10.0602 14.4281 12.0208 12.7837C13.6683 11.4025 14.5033 9.45625 14.5033 7C14.5022 6.01815 14.1405 5.07089 13.4868 4.33827C12.8331 3.60565 11.9331 3.13873 10.9577 3.02625ZM12.5033 6.4375L10.727 5.55188C10.6576 5.51711 10.581 5.49902 10.5033 5.49902C10.4256 5.49902 10.349 5.51711 10.2795 5.55188L8.50328 6.4375L7.66766 6.02C7.87141 5.43009 8.25407 4.91841 8.76234 4.55623C9.27061 4.19405 9.87918 3.9994 10.5033 3.9994C11.1274 3.9994 11.736 4.19405 12.2442 4.55623C12.7525 4.91841 13.1352 5.43009 13.3389 6.02L12.5033 6.4375Z"
                  :style="{ fill: getBranchConfig.html_color_palette?.Error }"
                />
              </svg>
            </p>
          </div>
        </div>
      </div>
    </div>
  </q-card>
  <!-- <q-card
    @click="food.is_active ? openModal(food) : ''"
    v-if="!food.image"
    flat
    bordered
    class="product-item-without-img"
  >
    <div class="food-info-without-img">
      <div>
        <h5 style="color: #082136; font-family: Prata;  font-size: 19px;">
          {{ food.name }}
        </h5>
      </div>

      <p
        class="p-best-seller"
        v-if="food.is_active"
        :style="{ borderRadius: 0 }"
      >
        $ {{ new Intl.NumberFormat("es-CO").format(food.total_price) }}
      </p>
    </div>

    <div
      @click="food.is_active ? openModal(food) : ''"
      size="87px"
      style="width: auto; height: 100%; max-height:124px"
      class="relative-position"
    ></div>
  </q-card> -->
</template>
<script>
import { mapGetters } from "vuex"
export default {
  props: {
    food: {
      type: Object,
      required: true,
    },
    openModal: {
      type: Function,
      required: true,
    },
    categoryAvailability: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getStyles", "getBranchConfig"]),
    withPrice() {
      return this.food.is_active
    },
    name() {
      const name = this.food.name.substring(0, 36)
      return name
    },
    description() {
      const description = this.food.description.substring(0, 58)
      return description
    },
    noImg() {
      return this.food.image === null
    },
  },
  methods: {
    // openModal() {
    //   this.$store.commit("setFoodConfig", this.food.id);
    //   window.$("#modalFood").modal("show");
    // },
  },
}
</script>
<style lang="scss" scoped>
.p-best-seller {
  height: 16px;
  display: flex;
  align-items: center;
}
.container-info-product {
  padding: 16px;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.icon-right {
  color: #ffffff !important;
}
.title-product {
  // padding-bottom: 8px;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // padding-bottom: 5px;
  // padding-top: 8px;
  // padding-left: 8px;
}
.learn-more {
  position: absolute;
  bottom: 10px; /* Ajusta según sea necesario */
  right: 5px; /* Ajusta según sea necesario */
  z-index: 1; /* Asegura que esté encima de todo */
}
.bagde-with-price {
  // Ajusta la posición del q-chip cuando hay un precio
  bottom: 0px !important; // Ajusta según sea necesario
}
.bagde-with-image {
  min-width: 120px;
  width: 100%;
  height: 32px;
  font-size: 12px;
  padding: 2px 5px 0 25px !important;
  display: flex;
  border-radius: 7px 0px 0px 0px !important;
  margin: 0 !important;
  margin-left: -5px;
}
.bagde-without-image {
  min-width: 120px;
  width: 100%;
  height: 32px;
  font-size: 12px; /* Ajusta el tamaño del texto según sea necesario */
  padding: 2px 5px 0 25px !important;
  border-radius: 7px 0px 0px 0px !important;
  margin: 0 !important;
}
.product-item-without-img {
  width: 100%;
  height: auto;
  max-height: 88px;

  // padding: 16px;

  display: grid;
  grid-template-columns: auto 48px;
  column-gap: 10px;
  background-color: #edf6fc;
  // background: var(--q-bg-contrast);
  // border-radius: 5px;
  border-radius: 0 !important;
  text-align: start;
}
.product-item {
  width: 208px;
  height: 217px;
  max-height: 217px;

  // padding: 16px;

  display: grid;
  grid-template-columns: auto 88px;
  column-gap: 10px;
  background-color: #edf6fc;
  // background: var(--q-bg-contrast);
  // border-radius: 5px;

  text-align: start;
}
.food-img {
  height: 93px;
  width: 93px;
  background-color: gray;
  //border-radius: 4px !important;
  border: none;
  align-self: center;
  object-fit: cover;
}
.food-info-without-img {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  align-items: flex-start;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-top: 16px;

  small {
    font-size: 12px;
  }
}
.food-info {
  width: 208px;
  height: 217px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  align-items: flex-start;
  //   padding-left: 16px;
  //   padding-bottom: 16px;
  //   padding-top: 16px;

  small {
    font-size: 12px;
  }
}
.info {
  max-height: 72px;
}
p {
  margin: 0;
  text-align: start;
  line-height: 12px;
}
small {
  margin-top: 5px;
  font-size: 12px;
  text-align: start;
  height: 41px;
  line-height: 21px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.add-btn {
  width: 36px;
  height: 36px !important;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0;
  // border-radius: 5px 0px;
}
/* @media screen and (min-width: 1023px) {
    .food-item {
      min-width: 254px;
      width: 100%;
      max-width: 370px;
    }
  }
  @media screen and (min-width: 1359px) {
    .food-item {
      height: 157px;
      padding: 16px;
    }
    .add-button {
      height: 44px;
      width: 44px;
    }
  } */
</style>
