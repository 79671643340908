<template>
  <section
    :style="{
      background: getBranchConfig.html_color_palette?.Secondary,
    }"
    :class="{
      'so-categories-visibilityInput': !visibilityInput,
      'so-categories': visibilityInput,
    }"
    @click="collapseProfile"
  >
    <div v-if="buttons.length == 0" class="so-non-menu">
      There is still no menu available for this restaurant.
    </div>
    <div v-else class="so-container-categories">
      <div
        v-if="visibilityInput"
        class="input so-input-container fs-16 body-font body-color"
      >
        <q-input
          borderless
          v-model="text"
          type="search"
          @input="filterByText"
          :placeholder="$t('pages.menu.searchProduct')"
          :style="{
            ...getStyles?.p,
            color: getStyles.p?.color,
            borderRadius: `${getBranchConfig?.radius}px`,
            background: getBranchConfig.html_color_palette?.Primary,
            border: `1px solid ${getBranchConfig.html_color_palette?.Stroke}`,
          }"
        >
          <template v-slot:prepend>
            <q-icon
              class=" q-pl-md "
              name="search"
              :style="{ color: getBranchConfig.html_color_palette?.Stroke }"
            />
          </template>
        </q-input>
      </div>
      <div v-if="isFiltered == false" class="so-buttons">
        <ScrollableTabs
          :buttons="buttons"
          :moveToCategory="moveToCategory"
          :currentIndex="currentIndex"
        />
      </div>
      <hr
        :style="{ color: getBranchConfig.html_color_palette?.Stroke }"
        style="border: 0; border-top: 0.5px solid; margin-top: 0; margin-left: 20px; margin-right: 20px;"
      />
    </div>
  </section>
</template>
<script>
import ScrollableTabs from "./ScrollableTabs.vue"
import { mapGetters } from "vuex"

export default {
  components: {
    ScrollableTabs,
  },
  props: {
    position: {
      type: Number,
      required: true,
    },
    visibilityInput: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      coordinates: [],
      buttons: [],
      text: "",
      tab: "",
      categoriesTop: false,
      currentIndex: 0,
    }
  },
  watch: {
    text() {
      this.filterByText()
    },
    allProducts() {
      if (this.allProducts.length > 0) {
        if (this.buttons.length == 0) {
          this.createCategory()
        }
      }
    },
    categories() {
      if (this.categories.length > 0) {
        if (this.buttons.length == 0) {
          this.createCategory()
        }
        if (this.selectedCategoryId) {
          this.$nextTick(() => {
            this.moveToCategory()
          })
        }
      }
    },

    position() {
      if (!this.categoriesTop) {
        this.buttons.forEach((button, index) => {
          const categoryElement = document.getElementById(button.ref)
          if (categoryElement) {
            this.buttons[index].position = categoryElement.offsetTop
          }
        })
        this.categoriesTop = true
      }

      const scrollPosition = document.getElementById("menu-view").scrollTop

      let activated = false

      this.buttons.forEach((category, categoryIndex) => {
        if (
          category.position !== undefined &&
          scrollPosition >= category.position - 500 &&
          !activated
        ) {
          this.buttons.forEach((button, index) => {
            let activeAlgo = categoryIndex === index
            button.isActive = activeAlgo

            this.currentIndex = categoryIndex

            // if (!this.currentIndex == index) {

            //   this.currentIndex = index
            //   this.moveToCategory(index)
            // }
          })
          this.tab = category.text
        }
      })
    },
  },
  mounted() {
    this.filterByText()
  },
  computed: {
    ...mapGetters({
      isFiltered: "getIsFiltered",
      allProductsFiltered: "getProductsFiltered",
      allProducts: "getAllProducts",
      selectedCategoryId: "getSelectedCategoryId",
      categories: "getFilteredCategories",
    }),
    ...mapGetters(["getStyles", "getBranchConfig"]),
  },
  methods: {
    toogleCategories(id) {
      for (let index = 0; index < this.buttons.length; index++) {
        if (this.selectedCategoryId && this.buttons[index].categoryId == id) {
          this.buttons[index].active = true
          this.moveToCategory(index)
        } else {
          this.buttons[index].active = false
        }
      }
    },

    moveToCategory(index) {
      let selectedCategoryIndex = this.buttons.findIndex(
        button => button.categoryId === this.selectedCategoryId
      )
      selectedCategoryIndex =
        selectedCategoryIndex === -1 ? null : selectedCategoryIndex

      const button = this.buttons[index ?? selectedCategoryIndex]

      const category = document.querySelector(`#${button?.ref}`)

      if (!category) return

      const offset = button.isAvailable ? 300 : 500
      document.getElementById("menu-view").scrollTop =
        category.offsetTop - offset

      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].active = i === index || i === selectedCategoryIndex
        this.buttons[i].isActive = i === index || i === selectedCategoryIndex
      }

      this.currentIndex = index || selectedCategoryIndex
      this.$store.commit("setSelectedCategoryId", null)
    },
    createCategory() {
      // this.$nextTick(() => {

      for (let index = 0; index < this.allProductsFiltered.length; index++) {
        if (this.allProductsFiltered[index].products.length > 0) {
          // const categoryRef = this.allProductsFiltered[index].name.replace(
          //   /[\s/&]+/g,
          //   ""
          // )
          // const category = this.allProductsFiltered[index]
          // const categoryName = category.name
          const categoryName = this.allProductsFiltered[index].name
          const categoryRef = categoryName
            .replace(/\s+/g, "")
            .replace(/[&']/g, "")

          const button = {
            text: this.allProductsFiltered[index].name,
            active: false,
            id: index,
            categoryId: this.allProductsFiltered[index].id,
            ref: categoryRef,
            isAvailable: this.allProductsFiltered[index].is_available,
          }
          if (
            this.allProductsFiltered[index].specials_category &&
            this.allProductsFiltered[index].is_available
          ) {
            this.buttons.unshift(button)
          } else {
            this.buttons.push(button)
          }
        }
      }

      if (this.buttons.length == 0) {
        return
      }
      this.buttons[0].isActive = true
      this.tab = this.buttons[0].text
      this.filterByText()
      // })
    },
    filterByText() {
      this.$store.commit("filterByText", this.text)
    },
  },
}
</script>
<style lang="scss">
.so-categories-visibilityInput {
  padding-top: 0px;
  height: 71px;
  position: sticky; // esta position necesita una altura especifica, no auto
  top: 1px;
  width: 100%;
  // border-bottom: 1px solid var(--q-bg-contrast);
  // background-color: var(--q-bg-nav);
  // border-bottom: 1px solid rgba(219, 236, 250, 0.1);
  background-color: #0c2043;
  z-index: 99;
}
.body-font {
  color: #f6f6f6;

  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
// /* Esto es de modalProduct */
.active-button {
  color: var(
    --q-secondary
  ) !important; /* Cambiar el color del texto al color secundario */
  border-bottom: 2px solid var(--q-secondary) !important; /* Agregar un borde inferior de color secundario */
}
.so-input-container {
  padding-left: 20px;
  padding-right: 20px;
}
.btn-cat {
  white-space: nowrap;
  background-color: var(--q-bg-nav);
  border: none;
  color: #f8f8f8;
  margin-top: 10px;

  padding: 1px 8px 13px;
  &:active {
    color: var(--q-secondary);
  }
}

.so-container-categories .q-field__control {
  &:focus-within {
    border: 2px solid white;
  }
}

.so-container-categories input {
  color: var(--q-body-color) !important;
}

.so-non-menu {
  width: 80%;
}
.so-categories {
  position: sticky; // esta position necesita una altura especifica, no auto
  top: 8px;
  height: 118px;
}
.so-container-categories {
  width: 100%;
  color: white;
  /* padding: 0 20px; */
}
.so-payfud-input {
  height: 48px;
}
.so-categories {
  width: 100%;
  // border-bottom: 1px solid var(--q-bg-contrast);
  // background-color: var(--q-bg-nav);
  // border-bottom: 1px solid rgba(219, 236, 250, 0.1);
  background-color: #0c2043;
  z-index: 99;
  // padding-top: 16px;
}
.so-buttons {
  padding-top: 24px;
  display: flex;
  overflow: auto;
  max-width: 100vw;
  .q-tabs__content {
    padding-left: 13px !important;
    padding-right: 13px;
  }
}
@media screen and (min-width: 426px) {
  .so-buttons {
    width: 408px;
  }
}
</style>
