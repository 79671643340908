<template>
  <!-- v-show="!loading" -->
  <q-layout
    id="menu-view"
    ref="scrollContainer"
    class="menu-layout scroll-y"
    @scroll="scrollHandler"
  >
    <Restaurant />
    <SearchOptions :position="position" :visibilityInput="visibilityInput" />
    <ProductsList :onlyRead="false" />
    <div
      id="sticky-header"
      class="another-header row"
      :class="position > 50 ? 'fixed-header' : ''"
      :style="
        position > 50
          ? { background: getBranchConfig.html_color_palette?.Secondary }
          : {}
      "
    ></div>
  </q-layout>
  <div v-show="loading" class="q-pa-md flex flex-center qloader">
    <q-circular-progress
      indeterminate
      rounded
      size="25px"
      color="white"
      class="q-ma-md"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
// import { setCssVar } from "quasar"
import Restaurant from "../components/Restaurant.vue"
import SearchOptions from "../components/SearchOptions.vue"
import ProductsList from "../components/ProductsList.vue"
export default {
  name: "menu-view",
  components: {
    Restaurant,
    SearchOptions,
    ProductsList,
  },
  data() {
    return {
      visibilityInput: true,
      loading: false,
      position: 0,
    }
  },
  computed: {
    ...mapGetters([
      "getAllProducts",
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
    ]),
    ...mapGetters("currentOrder", {
      products: "getProducts",
      preparingProducts: "getPreparingProducts",
      deliveredProducts: "getDeliveredProducts",
    }),
    productsCount() {
      return (
        this.products.length +
        this.preparingProducts.length +
        this.deliveredProducts.length
      )
    },
  },
  methods: {
    toOrders() {
      this.$router.push({
        name: "orders-v2",
      })
    },
    scrollHandler(w) {
      const direction = w.position > this.position ? "abajo" : "arriba"
      if (direction === "arriba") {
        this.visibilityInput = true
      } else {
        this.visibilityInput = false
      }
      this.position = w.position
    },
    fetchBranchInfo() {
      this.$store.dispatch("fetchBranchInfo")
      this.$store.dispatch("fetchBusinessInfo")
    },
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig").then(resp => {
        if (resp.status == 200) {
          // const styles = resp.data[0]
          // setCssVar("secondary", styles?.color || "#082136") // botones activos,required&notavailable bagde,skip & go menu,titulos,algunos precios
        }
      })
    },
    fetchBranchDetails() {
      this.$store.dispatch("fetchBranchDetails").then(resp => {
        if (resp.status == 200) {
          // const styles = resp.data || resp.detail || {}
          // setCssVar("bg-color", styles?.detail?.color_background) //esto es el background
          // setCssVar("disable-btn", styles?.detail?.disable_buttons || "#777") // esto para btn desactivados
          // setCssVar("body-color", styles?.detail?.body_color) // esto para los subtitulos
          // setCssVar("body-font", styles?.detail?.body_type)
          // setCssVar("title-font", styles?.detail?.title_type)
          // setCssVar("bg-nav", styles?.detail?.background_navigation)
          // setCssVar(
          //   "bg-contrast",
          //   styles?.detail?.background_contrast || "#303030"
          // )
          // setCssVar("review-link", styles?.review_link || "#DBB244")
        }
      })
    },
    fetchMenu() {
      this.$store.dispatch("fetchAllProducts").then(resp => {
        if (resp.status === 200) {
          // setTimeout(() => {
          this.loading = false
          // }, 1500)
        }
      })
    },
  },
  created() {
    this.loading = true
    this.$store.commit("setBusinessName", this.$route.params.business)
    this.fetchBranchInfo()
    this.fetchMenu()
  },
  mounted() {
    this.fetchBranchConfig()
    this.fetchBranchDetails()
  },
}
// calc(100vh - 50px);
</script>

<style lang="scss" scoped>
.qloader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0c2043;
  z-index: 100;
}
.menu-layout {
  max-width: 425px;
  height: auto; // aqui iria auto para que funcione
  display: grid;
  overflow-y: auto;
  grid-template-rows: 166px 110px calc(100vh - 326px);
  scroll-behavior: smooth;
  overflow-x: hidden;

  touch-action: pan-y;
}

.fab-btn {
  width: calc(100vw - 20px);
  max-width: 400px;
  min-width: 280px;
  border-radius: 10px;
  font-weight: bold;
}
.another-header {
  display: none;
}
.fixed-header {
  display: block;
  position: fixed;
  top: 0;
  z-index: 99;
  // background: #0c2043;
  width: 100%;
  max-width: 425px;
  min-width: 280px;
  display: flex;
  align-items: center;
  padding: 20px 20px 0;
}
</style>
