import api from "@/api"
import axios from "axios"

const business = {
  state: {
    businessName: null,
    idBranch: null,
    businessInfo: {},
    styles: {},
    backgroundColorApp: "",
  },
  mutations: {
    setBackgroundColor(state, color) {
      state.backgroundColorApp = color
    },
    setBusinessInfo(state, payload) {
      state.businessInfo = payload
      const name = state.businessInfo.name
      state.businessInfo.name = name.charAt(0).toUpperCase() + name.slice(1)
    },
    setStyles(state, payload) {
      state.styles = payload
    },
    setBusinessName(state, payload) {
      state.businessName = payload
    },
    setIdBranch(state, payload) {
      state.idBranch = payload
    },
  },
  actions: {
    fetchStyles({ rootState }) {
      return new Promise((resolve, reject) => {
        axios.get(`${api}/r/${rootState.businessName}/v1/web-fonts`).then(
          response => {
            resolve(response.data)
            // commit("setStyles", styles);
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetchBusinessInfo({ rootState, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${api}/v1/public-business?name=${rootState.businessName ||
              payload.businessName}`
          )
          .then(
            response => {
              resolve(response.data)
              commit("setBusinessInfo", response.data)
            },
            error => {
              reject(error)
            }
          )
      })
    },
  },
  getters: {
    getBusinessInfo(state) {
      return state.businessInfo
    },
    getStyles(state) {
      return state.styles
    },
  },
}

export default business
