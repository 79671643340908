<template>
  <q-layout
    v-show="!loading"
    id="menu-view"
    ref="scrollContainer"
    class="menu-layout scroll-y full-width"
    @scroll="scrollHandler"
  >
    <q-page-sticky position="top" class="sticky-breadcrumbs">
      <q-breadcrumbs separator="/" class="breadcrumbs">
        <q-breadcrumbs-el
          style="color: var(--q-body-color);"
          :label="$t('pages.menu.menus')"
          :to="{ name: 'main_online' }"
        />
        <q-breadcrumbs-el
          :label="menuName"
          style="color: var(--q-body-color);"
        />
      </q-breadcrumbs>
    </q-page-sticky>

    <RestaurantOnline />
    <SearchOptionsOnline :position="position" />
    <ProductsListOnline :onlyRead="false" />
    <div
      id="sticky-header"
      class="another-header row"
      :class="position > 50 ? 'fixed-header' : ''"
    >
      <q-avatar
        style="background-color: white; width: 37.03px;height: 37.03px;"
      >
        <img
          :src="getBranchDetails ? getBranchDetails.image : ''"
          style="width: 37.03px;
height: 37.03px;"
        />
      </q-avatar>
      <h3 class="q-ml-md text-secondary  fs-22">
        {{ getBusinessInfo ? getBusinessInfo.name : "Loading..." }}
      </h3>
    </div>
  </q-layout>
  <div class="fixed-menu" ref="myDiv">
    <q-tabs
      active-class="custom-active"
      v-model="activeTab"
      inline-label
      indicator-color="transparent"
      content-class="custom-tabs"
    >
      <q-route-tab
        style="background: var(--q-secondary); opacity: 2 !important; width: 100%;"
        icon="shopping_cart"
        :label="$t('pages.mainlayout.orderOnline')"
        href="https://www.ubereats.com/store/rose-bar-tampa/QV3LNzfkXYKIe-v3S8QV6A"
        no-caps
        tabindex="1"
        class="body-font  fs-14 custom-active tabs-menu"
        target="_blank"
        text-color="var (--q-bg-color)"
      ></q-route-tab>

      <q-route-tab
        icon="event"
        style="opacity: 2 !important; width: 100%; "
        :label="$t('pages.mainlayout.reserve')"
        href="https://www.google.com/maps/reserve/v/dine/c/-NS8ZmcgKVE?source=pa&opi=79508299&hl=en-US&gei=0wpuZfuGD_COwbkP0tCbqAg&sourceurl=https://www.google.com/maps/preview/place?authuser%3D0%26hl%3Den%26gl%3Dus%26pb%3D!1m20!1s0x88c2c3d9ff595ba7:0x58e9b2c69939fbec!3m12!1m3!1d18061.296301027265!2d-82.78863864999998!3d27.958527399999998!2m3!1f0!2f0!3f0!3m2!1i1838!2i984!4f13.1!4m2!3d27.9434579!4d-82.4827847!5e0!9e0!11s/g/11m_zxjvtt!12m4!2m3!1i360!2i120!4i8!13m57!2m2!1i203!2i100!3m2!2i4!5b1!6m6!1m2"
        no-caps
        tabindex="3"
        class=" body-font"
        target="_blank"
      >
      </q-route-tab>
    </q-tabs>
  </div>
  <div v-show="loading" class="q-pa-md flex flex-center">
    <q-circular-progress
      indeterminate
      rounded
      size="50px"
      color="lime"
      class="q-ma-md"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { setCssVar } from "quasar"

import RestaurantOnline from "../components/RestaurantOnline.vue"
import SearchOptionsOnline from "../components/SearchOptionsOnline.vue"
import ProductsListOnline from "../components/ProductsListOnline.vue"
export default {
  components: {
    RestaurantOnline,
    SearchOptionsOnline,
    ProductsListOnline,
  },
  data() {
    return {
      loading: false,
      position: 0,
      activeTab: 0,
    }
  },
  computed: {
    ...mapGetters([
      "getAllProducts",
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
    ]),
    ...mapGetters("currentOrder", {
      products: "getProducts",
    }),
    // getBusinessInfo() {
    //   return this.$store.getters.getBusinessInfo
    // },
    menuName() {
      return this.$route.params.menuName
    },
  },
  methods: {
    // setLocaleBasedOnLocation() {
    //   const businessInfo = this.getBusinessInfo // Obtiene la información del restaurante

    //   if (businessInfo) {
    //     const location = businessInfo.location
    //     if (location === "col") {
    //       this.$i18n.locale = "es"
    //     } else if (location === "us") {
    //       this.$i18n.locale = "en"
    //     }
    //   }
    // },
    fetchBusinessInfo() {
      this.$store.dispatch("fetchBusinessInfo").then(resp => {
        const location = resp.location
        if (location === "col") {
          this.$i18n.locale = "es"

          // Colombia
        } else if (location === "us") {
          this.$i18n.locale = "en"

          // Belongs to USA
        }
      })
    },
    activeRoute(route) {
      return this.$route.name == route
    },
    route() {
      return this.$route
    },
    scrollHandler(w) {
      this.position = w.position
    },
    fetchBranchInfo() {
      this.$store.dispatch("fetchBranchInfo")
      this.$store.dispatch("fetchBusinessInfo")
    },
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data[0]
          // setCssVar("secondary", styles?.color)
          setCssVar("card_stroke", styles?.card_stroke)
          setCssVar("instagram", styles?.instagram)
          setCssVar("review", styles?.review || "#DBB244")
          setCssVar("bg-options", styles?.background_options)
          setCssVar("bg-search", styles?.background_search)
          setCssVar("bg-products-item", styles?.background_products_item)
          setCssVar("bg-products", styles?.background_products)
          setCssVar("bg-modal", styles?.background_modal)
          setCssVar("bg-tabs", styles?.background_tabs)
        }
      })
    },
    fetchBranchDetails() {
      this.$store.dispatch("fetchBranchDetails").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data || resp.detail || {}
          setCssVar("bg-color", styles?.detail?.color_background) //esto es el background
          setCssVar("disable-btn", styles?.detail?.disable_buttons) // esto para btn desactivados
          setCssVar("body-color", styles?.detail?.body_color) // esto para los subtitulos
          setCssVar("body-font", styles?.detail?.body_type)
          setCssVar("title-font", styles?.detail?.title_type)
          setCssVar("bg-nav", styles?.detail?.background_navigation)
          setCssVar("bg-contrast", styles?.detail?.background_contrast)
        }
      })
    },
    fetchMenu() {
      const menuName = this.$route.params.menuName

      this.$store.dispatch("fetchMenuTime", { name: menuName }).then(resp => {
        if (resp.status === 200) {
          // setTimeout(() => {
          this.loading = false
          // }, 2000)
        }
      })
    },
  },

  created() {
    this.loading = true
    this.$store.commit("setBusinessName", this.$route.params.business)
    this.fetchBranchInfo()
    this.fetchMenu()
  },
  mounted() {
    this.fetchBranchConfig()
    this.fetchBranchDetails()
    this.fetchBusinessInfo()
  },
}
</script>

<style lang="scss" scoped>
.tabs-menu {
  height: 60px;
}
.sticky-breadcrumbs {
  z-index: 100;
}
.breadcrumbs {
  margin-top: 14px;
  margin-bottom: 20px;
  color: var(--q-body-color);
  width: 100vw;
  max-width: 1010px;
  padding-left: 18px;
}
.body-font {
  font-family: var(--q-body-font);
}
.custom-active {
  color: var(--q-bg-color) !important;
}
.button {
  background-color: #04aa6d;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.custom-tabs q-tab {
  background-color: var(--q-secondary);
  color: #fff;
}
.fixed-menu {
  position: fixed;
  bottom: 0;
  width: 100vw;
  max-width: 1010px;
  min-width: 360px;
  // border-top: 1px solid var(--q-bg-nav);
  background-color: var(--q-bg-color);
}
.menu-layout {
  max-width: 1010px;
  height: calc(100vh - 50px);
  display: grid;
  overflow-y: auto;
  grid-template-rows: 166px 110px calc(100vh - 326px);
  scroll-behavior: smooth;
}

.fab-btn {
  width: calc(100vw - 20px);
  max-width: 400px;
  min-width: 280px;
  border-radius: 10px;
  font-weight: bold;
}
.another-header {
  display: none;
}
.fixed-header {
  display: block;
  position: fixed;
  top: 0;
  z-index: 99;
  // background-color: var(--q-bg-color);
  width: 100%;
  max-width: 1010px;
  min-width: 280px;
  display: flex;
  align-items: center;
  padding: 46px 20px 0;
}
@media screen and (max-width: 415px) {
  .fixed-header {
    display: block;
    position: fixed;
    top: 0;
    z-index: 99;
    // background-color: var(--q-bg-color);
    width: 100%;
    max-width: 425px;
    min-width: 280px;
    display: flex;
    align-items: center;
    padding: 43px 20px 0;
  }
  .menu-layout {
    max-width: 425px;
    height: calc(100vh - 50px);
    display: grid;
    overflow-y: auto;
    grid-template-rows: 166px 110px calc(100vh - 326px);
    scroll-behavior: smooth;
  }

  .buttons button {
    width: 112px;
  }

  .tabs-menu {
    height: 48px;
  }
}

// @media screen and (max-width: 820px) {
// }
// .breadcrumbs {
//   margin-left: -389px;

//   margin-top: 14px;
//   margin-bottom: 20px;
//   color: var(--q-body-color);
// }
// @media screen and (max-width: 1180px) {
// }
// .breadcrumbs {
//   margin-left: -482px;

//   margin-top: 14px;
//   margin-bottom: 20px;
//   color: var(--q-body-color);
// }
</style>
