<template>
  <div
    class="restaurant"
    :style="{ background: getBranchConfig.html_color_palette?.Secondary }"
  >
    <section class="profile">
      <div class="image-container">
        <img
          :src="getBusinessInfo.image"
          alt="Business Logo"
          class="round-image"
        />
      </div>
      <div class="profile-info">
        <h1
          class="text-white title-font fs-22 q-pb-sm"
          :style="{ ...getStyles.h1, color: getStyles.h1?.color }"
        >
          {{ getBusinessInfo.name }}
        </h1>
        <q-btn
          no-caps
          class="q-ml-none q-mr-sm q-py-sm"
          style="height: 38px !important; padding-inline: 12px ; padding-bottom: 8px; padding-top: 8px; min-height: 38px;"
          text-color="dark"
          :style="{
            borderRadius: `${getBranchConfig?.radius}px`,
            background: getBranchConfig.html_color_palette?.Accent,
          }"
        >
          <!-- :href="getBranchConfig[0] && getBranchConfig[0].instagram_link" -->
          <a
            href="https://www.instagram.com/avenueblutampa/"
            class="socials-link "
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              class=""
              width="16"
              height="16"
              viewBox="0 0 16 16"
              :style="{ fill: getBranchConfig.html_color_palette?.Primary }"
            >
              <path
                d="M7.998 5.3322C6.52901 5.3322 5.3302 6.53101 5.3302 8C5.3302 9.46899 6.52901 10.6678 7.998 10.6678C9.46699 10.6678 10.6658 9.46899 10.6658 8C10.6658 6.53101 9.46699 5.3322 7.998 5.3322ZM15.9994 8C15.9994 6.89525 16.0094 5.80051 15.9474 4.69777C15.8853 3.4169 15.5931 2.28014 14.6565 1.3435C13.7179 0.404868 12.5831 0.114672 11.3022 0.0526304C10.1975 -0.0094115 9.10274 0.000595291 8 0.000595291C6.89525 0.000595291 5.80051 -0.0094115 4.69777 0.0526304C3.4169 0.114672 2.28014 0.40687 1.3435 1.3435C0.404868 2.28214 0.114672 3.4169 0.0526304 4.69777C-0.0094115 5.80252 0.000595291 6.89725 0.000595291 8C0.000595291 9.10275 -0.0094115 10.1995 0.0526304 11.3022C0.114672 12.5831 0.40687 13.7199 1.3435 14.6565C2.28214 15.5951 3.4169 15.8853 4.69777 15.9474C5.80252 16.0094 6.89725 15.9994 8 15.9994C9.10475 15.9994 10.1995 16.0094 11.3022 15.9474C12.5831 15.8853 13.7199 15.5931 14.6565 14.6565C15.5951 13.7179 15.8853 12.5831 15.9474 11.3022C16.0114 10.1995 15.9994 9.10475 15.9994 8ZM7.998 12.1048C5.72646 12.1048 3.89323 10.2715 3.89323 8C3.89323 5.72847 5.72646 3.89523 7.998 3.89523C10.2695 3.89523 12.1028 5.72847 12.1028 8C12.1028 10.2715 10.2695 12.1048 7.998 12.1048ZM12.2709 4.68576C11.7405 4.68576 11.3122 4.25747 11.3122 3.72711C11.3122 3.19675 11.7405 2.76847 12.2709 2.76847C12.8012 2.76847 13.2295 3.19675 13.2295 3.72711C13.2297 3.85305 13.205 3.97778 13.1569 4.09416C13.1088 4.21054 13.0382 4.31628 12.9491 4.40533C12.8601 4.49438 12.7543 4.56499 12.6379 4.61311C12.5216 4.66123 12.3968 4.68592 12.2709 4.68576Z"
              />
            </svg>
            <p
              style="margin-left: 6px;"
              class=" q-mb-none  "
              :style="{ ...getStyles?.p, color: getStyles.p?.colorSecondary }"
            >
              Follow
            </p>
          </a>
        </q-btn>
        <q-btn
          no-caps
          class="q-ml-none"
          style="height: 38px; padding-inline: 12px ; padding-bottom: 8px; padding-top: 8px; min-height: 38px !important;"
          text-color="secondary"
          :style="{
            borderRadius: `${getBranchConfig?.radius}px`,
            background: getBranchConfig.html_color_palette?.Secondary,
            border: `1px solid ${getBranchConfig.html_color_palette?.Accent}`,
          }"
        >
          <a
            :href="getBranchDetails?.review_link"
            class="socials-link "
            target="_blank"
            rel="noopener noreferrer"
            style="height: 22px;"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              :style="{ fill: getBranchConfig.html_color_palette?.Accent }"
            >
              <path
                d="M4.90299 9.77441H6.53633L10.4697 5.87441C10.5363 5.80775 10.5697 5.72441 10.5697 5.62441C10.5697 5.52441 10.5363 5.44108 10.4697 5.37441L9.30299 4.20775C9.23633 4.14108 9.15299 4.10775 9.05299 4.10775C8.95299 4.10775 8.86966 4.14108 8.80299 4.20775L4.90299 8.14108V9.77441ZM7.90299 9.77441H12.903V8.44108H9.23633L7.90299 9.77441ZM2.23633 15.1077V3.10775C2.23633 2.74108 2.36688 2.42719 2.62799 2.16608C2.88911 1.90497 3.20299 1.77441 3.56966 1.77441H14.2363C14.603 1.77441 14.9169 1.90497 15.178 2.16608C15.4391 2.42719 15.5697 2.74108 15.5697 3.10775V11.1077C15.5697 11.4744 15.4391 11.7883 15.178 12.0494C14.9169 12.3105 14.603 12.4411 14.2363 12.4411H4.90299L2.23633 15.1077Z"
              />
            </svg>
            <p
              style="margin-left: 6px;"
              class=" q-mb-none   "
              :style="{ ...getStyles?.p, color: getStyles.p?.color }"
            >
              Review
            </p>
          </a>
        </q-btn>
      </div>
    </section>
    <p
      class="description-text  "
      :style="{ ...getStyles?.p, color: getStyles.p?.color }"
    >
      {{ getBusinessInfo.description }}
    </p>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "restaurant-vue",
  computed: {
    restaurant() {
      return this.$store.getters.getRestaurant
    },
    scroll() {
      return document.querySelector("#menu-view").scrollTop
    },
    ...mapGetters([
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
      "getStyles",
    ]),
  },
}
</script>
<style lang="scss" scoped>
.image-container {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(169, 156, 62, 0.08);
}

.round-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.sub-font {
  color: #fff;

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.title-font {
  color: #fff;
  text-align: left !important;
  font-family: Prata;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.restaurant {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #0c2043;
  // background-color: var(--q-bg-nav);
  padding: 20px 20px 16px 20px;
  font-family: Playfair Display;
}
.profile {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 220px;
  text-align: start;
}
.profile-info {
  margin-left: 16px;
  margin-bottom: 16px;

  .q-chip {
    padding: 4px 6px;
  }
}
.restaurant-name {
  font-size: 20px;
}
.collapse {
  height: 0px;
}
figure {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  display: flex;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(169, 156, 62, 0.08);
  margin: 0;
  margin-right: 12px;
}
img {
  height: 100%;
}
h1 {
  margin: 0;
}
.description-text {
  text-align: start;
  letter-spacing: 0;
  margin-bottom: 0px;
}
.social-media {
  margin-top: 8px;
}
.social-media img {
  height: auto;
  margin-right: 10px;
}
.socials-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 22px;
}
</style>
