<template>
  <div
    class="help-view"
    :style="{
      backgroundColor: getBranchConfig?.html_color_palette?.Secondary,
    }"
  >
    <!-- <q-img
      src="@/assets/images/helpview.png"
      height="140px"
      width="140px"
      class="q-mb-xl"
    /> -->
    <h1
      class="q-mb-sm title-font"
      style="font-weight: 600;"
      :style="{ ...getStyles?.h1, color: getStyles.h1?.color }"
    >
      Service
    </h1>
    <p
      style="margin-bottom: 30px; color:white"
      class="body-font"
      :style="{ ...getStyles?.p, color: getStyles.p?.color }"
    >
      Select the service you need
    </p>
    <q-btn
      :style="{
        ...getStyles?.hp,
        color: getStyles.p?.colorSecondary,
        backgroundColor: getBranchConfig.html_color_palette?.Accent,
      }"
      style="background-color: white ; color: #082136;"
      class="fw q-mb-md "
      unelevated
      label="Order"
      no-caps
      @click="onClickServiceButton('server')"
      :disable="counting || gettingLocation || !isWithinRange"
    >
      <vue-countdown
        class="q-ml-md"
        v-if="counting"
        :time="timeRemaining"
        @end="onCountdownEnd"
        v-slot="{ minutes, seconds }"
      >
        <vue-countdown
          v-if="counting"
          :time="timeRemaining"
          @end="onCountdownEnd"
          v-slot="{ totalSeconds }"
        >
          <q-circular-progress
            show-value
            class="text-white q-ma-xs"
            :value="totalSeconds"
            :min="0"
            :max="300"
            size="30px"
            color="white"
            :thickness="0.05"
          >
            {{ minutes }} : <template v-if="seconds < 10">0</template
            >{{ seconds }}
          </q-circular-progress>
        </vue-countdown>
      </vue-countdown>
    </q-btn>

    <q-btn
      :style="{
        ...getStyles?.hp,
        color: getStyles.p?.color,
        border: `1px solid ${getBranchConfig.html_color_palette?.Accent}`,
      }"
      class="fw q-mb-md text-white body-font "
      color="primary"
      outline
      label="Check-Out"
      no-caps
      @click="onClickServiceButton('checkout')"
      :disable="counting2 || gettingLocation || !isWithinRange"
    >
      <vue-countdown
        class="q-ml-md"
        v-if="counting2"
        :time="timeRemaining2"
        @end="onCountdownEnd2"
        v-slot="{ minutes, seconds }"
      >
        <vue-countdown
          v-if="counting2"
          :time="timeRemaining2"
          @end="onCountdownEnd2"
          v-slot="{ totalSeconds }"
        >
          <q-circular-progress
            show-value
            class="text-black q-ma-xs"
            :value="totalSeconds"
            :min="0"
            :max="300"
            size="30px"
            color="black"
            :thickness="0.05"
          >
            {{ minutes }} : <template v-if="seconds < 10">0</template
            >{{ seconds }}
          </q-circular-progress>
        </vue-countdown>
      </vue-countdown>
    </q-btn>
    <q-btn
      :style="{
        ...getStyles?.p,
        color: getStyles.p?.color,
        borderRadius: `${getBranchConfig?.radius}px`,
      }"
      class="fw q-mb-md  "
      unelevated
      label="Other"
      no-caps
      flat
      @click="onClickServiceButton('other')"
      :disable="counting3 || gettingLocation || !isWithinRange"
    >
      <vue-countdown
        class="q-ml-md"
        v-if="counting3"
        :time="timeRemaining3"
        @end="onCountdownEnd3"
        v-slot="{ minutes, seconds }"
      >
        <vue-countdown
          v-if="counting"
          :time="timeRemaining3"
          @end="onCountdownEnd3"
          v-slot="{ totalSeconds }"
        >
          <q-circular-progress
            show-value
            class="text-white q-ma-xs"
            :value="totalSeconds"
            :min="0"
            :max="300"
            size="30px"
            color="white"
            :thickness="0.05"
          >
            {{ minutes }} : <template v-if="seconds < 10">0</template
            >{{ seconds }}
          </q-circular-progress>
        </vue-countdown>
      </vue-countdown>
    </q-btn>
    <!-- <q-btn
      class="fw"
      color="primary"
      flat
      label="Calificar servicio"
      no-caps
      @click="toggleRatingModal"
    /> -->
  </div>

  <ModalErrorNotifications v-model="visiblity.modalError" />
  <!-- <ModalInformationRequired
    v-model="visiblity.requiredInformation"
    :onRequestService="onRequestService"
    :serviceName="serviceName"
  /> -->
  <ModalLocationRequired v-model="visiblity.requiredLocation" />
  <!-- <ModalRating v-model="ratingModal" :openAlert="toggleModalGratitude" /> -->
  <!-- <ModalRequestCheck v-model="requestCheckModal" :requestBill="onBillRequest" /> -->
  <!-- <ModalGratitude v-model="gratitudeModal" /> -->
</template>

<script>
// import { setCssVar } from "quasar"
import { defineAsyncComponent } from "vue"
import VueCountdown from "@chenfengyuan/vue-countdown"
import { mapGetters } from "vuex"
import { useQuasar } from "quasar"
// import { fetchLocation } from "../../../../src/store/modules/socket/actions"
import { mapActions } from "vuex"
export default {
  components: {
    // ModalRating: defineAsyncComponent(() =>
    //   import("../modals/ModalRating.vue")
    // ),
    // ModalInformationRequired: defineAsyncComponent(() =>
    //   import("../../auth/modals/ModalInformationRequiredFromHelp.vue")
    // ),
    ModalLocationRequired: defineAsyncComponent(() =>
      import("../../auth/modals/ModalLocationRequired.vue")
    ),
    //ModalPhoneRequired: defineAsyncComponent(() =>
    //  import("../../auth/modals/ModalPhoneRequired.vue")
    //),
    // ModalRequestCheck: defineAsyncComponent(() =>
    //   import("../modals/ModalRequestCheck.vue")
    // ),
    ModalErrorNotifications: defineAsyncComponent(() =>
      import("../../auth/modals/ModalErrorNotifications.vue")
    ),
    VueCountdown,
  },
  data() {
    return {
      logoutTime: null,
      gettingLocation: false,
      timeRemaining: 0,
      timeRemaining2: 0,
      timeRemaining3: 0,
      isWithinRange: false,
      ratingModal: false,
      requestCheckModal: false,
      visiblity: {
        requiredInformation: false,
        requiredLocation: false,
        modalError: false,
      },
      gratitudeModal: false,
      counting: false,
      counting2: false,
      counting3: false,
      cdMinutes: 5,
      cdSeconds: 60,
      location: null,
      serviceName: "",
    }
  },
  mounted() {
    if (this.getRequestServer) this.initializeCountdown()
    if (this.getRequestCheck) this.initializeCountdown2()
    this.getupdatedLocation()
    this.startLogoutTime()
    this.setModalError()
  },
  watch: {
    getSocketError() {
      this.setModalError()
    },
  },
  computed: {
    ...mapGetters(["getRequestServer", "getRequestCheck"]),
    ...mapGetters(["getBusinessInfo", "getSocketConnection"]),
    ...mapGetters(["getBranchInfo", "getStyles", "getBranchConfig"]),
    ...mapGetters("socket", ["getLocation", "getSocketError"]),
    ...mapGetters("currentOrder", [
      "getOrderId",
      "getPreparingProducts",
      "getDeliveredProducts",
    ]),
    ...mapGetters("socket", {
      connectionId: "getConnectionId",
      socket: "getSocket",
      phoneData: "getPhoneData",
    }),
    disableBtn() {
      return (
        this.getDeliveredProducts.length === 0 &&
        this.getPreparingProducts.length === 0
      )
    },
  },

  methods: {
    ...mapActions("socket", ["fetchLocation"]),

    sendTotalCheckOutRequest() {
      const id = this.$route.params.id
      const payload = {
        client_id: this.phoneData.phone || "NRU",
        frequent_customer: this.phoneData.phone || "NRU",
        sucursal: id,
        type: "bill",
      }
      this.$store.dispatch("totalSessions", payload)
    },
    sendTotalHelpRequest() {
      const id = this.$route.params.id
      const payload = {
        client_id: this.phoneData.phone || "NRU",
        frequent_customer: this.phoneData.phone || "NRU",
        sucursal: id,
        type: "waiter",
      }
      this.$store.dispatch("totalSessions", payload)
    },

    setModalError() {
      this.visiblity.modalError = !!this.getSocketError
    },

    showModalError(errorMessage) {
      // aqui helpview escucha ese error y por medio de la ref del modal entonces lo abre
      this.$refs.errorModal.show(errorMessage)
    },

    startLogoutTime() {
      this.logoutTimer = setTimeout(() => {
        this.checkAndLogout()
      }, 14400000) // 4 horas en milisegundos
    },
    checkAndLogout() {
      if (!this.isWithinRange || !this.location) {
        // Realizar la desconexión aquí

        this.$router.push({ name: "us-logout" })
      }
    },
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig")
      // .then(resp => {
      //   if (resp.status == 200) {
      //     const styles = resp.data[0]
      //     setCssVar("bg-color", styles?.color_background || "#212121") //esto es el background
      //     setCssVar("secondary", styles?.color || "#DBB244") // botones activos,required&notavailable bagde,skip & go menu,titulos,algunos precios
      //     setCssVar("disable-btn", styles?.disable_buttons || "#777") // esto para btn desactivados
      //     setCssVar("body-color", styles?.body_color || "#FFF") // esto para los subtitulos
      //     setCssVar("body-font", styles?.body_type || "Georgia")
      //     // setCssVar("title-font", styles?.title_type || "Playfair Display")
      //     setCssVar("bg-nav", styles?.background_navigation || "#151515")
      //     setCssVar("bg-contrast", styles?.backgorund_contrast || "#303030")
      //     // setCssVar("review-link", styles?.review_link || "#DBB244")
      //   }
      //   this.loading = false
      // })
    },

    toggleRatingModal() {
      this.ratingModal = !this.ratingModal
    },
    toggleRequestCheckModal() {
      this.requestCheckModal = !this.requestCheckModal
    },
    toggleModalGratitude() {
      this.gratitudeModal = !this.gratitudeModal
    },
    initializeCountdown() {
      const cadenaDeTiempo = this.getRequestServer
      const currentHour = new Date()
      const oldHour = new Date() // Obtiene la fecha y hora actual
      const [hora, minutos, segundos] = cadenaDeTiempo.split(":") // Divide la cadena en hora, minutos y segundos

      oldHour.setHours(hora, minutos, segundos)

      const diferenciaEnMilisegundos = currentHour - oldHour

      // Calcular 5 minutos en milisegundos (5 * 60 * 1000)
      const cincoMinutosEnMilisegundos = 5 * 60 * 1000

      if (this.$store.state.socket.phoneId && this.$store.state.socket.phone) {
        if (diferenciaEnMilisegundos > cincoMinutosEnMilisegundos) {
          // Si han pasado más de 5 minutos, haz algo
          this.counting = false

          // Ejecuta tu acción aquí
        } else {
          this.counting = true

          this.timeRemaining =
            cincoMinutosEnMilisegundos - diferenciaEnMilisegundos
        }
      }
    },
    initializeCountdown2() {
      const cadenaDeTiempo = this.getRequestCheck
      const currentHour = new Date()
      const oldHour = new Date() // Obtiene la fecha y hora actual
      const [hora, minutos, segundos] = cadenaDeTiempo.split(":") // Divide la cadena en hora, minutos y segundos
      oldHour.setHours(hora, minutos, segundos)
      const diferenciaEnMilisegundos = currentHour - oldHour

      // Calcular 5 minutos en milisegundos (5 * 60 * 1000)
      const cincoMinutosEnMilisegundos = 5 * 60 * 1000
      if (diferenciaEnMilisegundos > cincoMinutosEnMilisegundos) {
        // Si han pasado más de 5 minutos, haz algo
        this.counting2 = false

        // Ejecuta tu acción aquí
      } else {
        this.counting2 = true
        this.timeRemaining2 =
          cincoMinutosEnMilisegundos - diferenciaEnMilisegundos
      }
    },
    initializeCountdown3() {
      const cadenaDeTiempo = this.getRequestCheck
      const currentHour = new Date()
      const oldHour = new Date() // Obtiene la fecha y hora actual
      const [hora, minutos, segundos] = cadenaDeTiempo.split(":") // Divide la cadena en hora, minutos y segundos
      oldHour.setHours(hora, minutos, segundos)
      const diferenciaEnMilisegundos = currentHour - oldHour

      // Calcular 5 minutos en milisegundos (5 * 60 * 1000)
      const cincoMinutosEnMilisegundos = 5 * 60 * 1000
      if (diferenciaEnMilisegundos > cincoMinutosEnMilisegundos) {
        // Si han pasado más de 5 minutos, haz algo
        this.counting3 = false

        // Ejecuta tu acción aquí
      } else {
        this.counting3 = true
        this.timeRemaining3 =
          cincoMinutosEnMilisegundos - diferenciaEnMilisegundos
      }
    },
    async startCountdown() {
      const horaActual = new Date().toLocaleTimeString()

      this.$store.commit("setRequestServer", horaActual)

      // this.getLocation()
      try {
        const horaActual = new Date().toLocaleTimeString()

        this.$store.commit("setRequestServer", horaActual)

        // if (!this.phoneData.phoneId && !this.phoneData.phone) {
        //   this.visiblity.requiredInformation = true
        //   return
        // }

        this.counting = true
        this.initializeCountdown()
        this.showNotifs()
        let current_counting = this.counting
        setTimeout(() => {
          if (current_counting) this.onCallWaiter()
        }, 5000)
      } catch (error) {
        console.error("Error fetching location:", error)
      }
    },
    getupdatedLocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available."
        return
      }
      this.gettingLocation = true
      // get position
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.gettingLocation = false
          this.location = {
            accuracy: String(pos.coords.accuracy),
            altitude: String(pos.coords.altitude),
            altitudeAccuracy: String(pos.coords.altitudeAccuracy),
            heading: String(pos.coords.heading),
            latitude: String(pos.coords.latitude),
            longitude: String(pos.coords.longitude),
            speed: String(pos.coords.speed),
          }

          this.fetchLocation(this.location).then(response => {
            if (response.data.within_range === false) {
              // this.$router.push({ name: "us-logout" }) // esto deberia pasar solo despues de 4 horas de volver a validaer ubicación
              this.visiblity.requiredLocation = true
            } else {
              this.isWithinRange = true
            }
          })

          // Manejo de error de getCurrentPosition
        },
        err => {
          this.gettingLocation = false
          this.visiblity.requiredLocation = true
          this.errorStr = err.message
        }
      )
    },
    startCountdown2() {
      //ALATERNATIVA 1 PARA EL FUNCIONAMIENTO SIN TIMER
      const horaActual = new Date().toLocaleTimeString()

      this.$store.commit("setRequestServer", horaActual)

      // this.getLocation()
      try {
        const horaActual = new Date().toLocaleTimeString()

        this.$store.commit("setRequestServer", horaActual)
        this.counting = true
        this.initializeCountdown2()
        this.showNotifs2()
        let current_counting = this.counting
        setTimeout(() => {
          if (current_counting) this.onBillRequest()
        }, 5000)
      } catch (error) {
        console.error("Error fetching location:", error)
      }
      if (!this.phoneData.phoneId && !this.phoneData.phone) {
        this.visiblity.requiredInformation = true
        return
      }
      // ALATERNATIVA 2 PARA EL FUNCIONAMIENTO CON TIMER
      // const horaActual = new Date().toLocaleTimeString()
      // this.$store.commit("setRequestCheck", horaActual)
      // this.counting2 = true
      // this.initializeCountdown2()
      // this.showNotifs2()
      // let current_counting = this.counting2
      // setTimeout(() => {
      //   if (current_counting) this.onBillRequest()
      // }, 5000)
    },

    onCountdownEnd() {
      this.counting = false
    },
    onCountdownEnd2() {
      this.counting2 = false
    },
    onCountdownEnd3() {
      this.counting3 = false
    },
    async onCallWaiter() {
      // if (!this.phoneData.phoneId && !this.phoneData.phone) {
      //   this.visiblity.requiredInformation = true
      //   return
      // }

      this.getupdatedLocation()
      const socketURL =
        "wss://6wpovdvzcg.execute-api.us-east-1.amazonaws.com/production"
      const socket = new WebSocket(socketURL)
      await new Promise(resolve => {
        socket.addEventListener("open", resolve)
      })
      const messageBody = {
        action: "call_Waiter",
        restaurant_location_id: String(this.getBranchInfo[0].id),
        restaurant_id: this.getBusinessInfo.name,
        restaurant_name: this.getBusinessInfo.name,
        table: this.$route.params.table,
        phone_number: this.phoneData.phone,
        phone_id: String(this.phoneData.phoneId),
      }
      socket.send(JSON.stringify(messageBody))
    },
    async onBillRequest() {
      // if (!this.phoneData.phoneId && !this.phoneData.phone) {
      //   this.visiblity.requiredInformation = true
      //   return
      // }

      this.getupdatedLocation()
      const socketURL =
        "wss://6wpovdvzcg.execute-api.us-east-1.amazonaws.com/production"
      const socket = new WebSocket(socketURL)
      await new Promise(resolve => {
        socket.addEventListener("open", resolve)
      })
      const messageBody = {
        action: "order_Bill",
        restaurant_location_id: this.getBranchInfo[0].id,
        restaurant_name: this.getBusinessInfo.name,
        table: this.$route.params.table,
        order_id: 1,
        payment_type: "1",
        setTip: "1",
        phone_number: String(this.phoneData.phone),
        phone_id: String(this.phoneData.phoneId),
      }
      socket.send(JSON.stringify(messageBody))
    },
    onClickServiceButton(serviceName) {
      this.serviceName = serviceName
      if (!this.location) {
        this.visiblity.requiredLocation = true
        return
      }
      // if (!this.phoneData.phoneId && !this.phoneData.phone) {
      //   this.visiblity.requiredInformation = true
      //   return
      // }
      this.onRequestService(serviceName)
    },
    onRequestService(serviceName) {
      if (serviceName == "server") {
        this.sendTotalHelpRequest()
        this.startCountdown()
      } else if (serviceName == "checkout") {
        this.sendTotalCheckOutRequest()
        // this.onCallWaiter()
        // this.startCountdown2()
        this.startCountdown2()
        // this.onBillRequest()
      }
    },
  },
  setup() {
    const $q = useQuasar()

    return {
      showNotifs() {
        $q.notify({
          classes: "custom-notification",
          progress: true,
          message: "Requested server",
          icon: "check",
          html: true,
          textColor: this.getBranchConfig.html_color_palette?.Primary,

          actions: [
            {
              label: "CANCEL",
              style: {
                fontSize: this.getStyles?.p?.fontSize,
                color: this.getBranchConfig.html_color_palette?.Primary,
              },
              noCaps: true,
              handler: () => {
                this.onCountdownEnd()
              },
            },
          ],
        })
      },

      showNotifs2() {
        $q.notify({
          progress: true,
          classes: "custom-notification",
          icon: "check",
          message: "Requested check-out",
          html: true,
          textColor: this.getBranchConfig.html_color_palette?.Primary,
          // icon: "mail",
          class: " body-font",
          // color: "secondary",
          // textColor: "white",
          actions: [
            {
              label: "CANCEL",
              style: "",

              noCaps: true,
              handler: () => {
                this.onCountdownEnd2()
              },
            },
          ],
        })
      },
      showNotifs3() {
        $q.notify({
          progress: true,
          message: "Requested other",
          classes: "custom-notification",
          icon: "check",
          class: " body-font",
          html: true,
          textColor: this.getBranchConfig.html_color_palette?.Primary,
          // color: "secondary",
          // textColor: "white",
          actions: [
            {
              label: "CANCEL",
              style: " ",
              color: "white",
              noCaps: true,
              handler: () => {
                this.onCountdownEnd3()
              },
            },
          ],
        })
      },
    }
  },
}
</script>

<style lang="scss" scoped>
// .custom-notification {
//   ::v-deep .q-notification {
//     background-color: var(--q-bagde-color) !important;
//     color: var(--q-text-bagde-color) !important;
//     background: var(--q-bagde-color) !important;
//   }

//   ::v-deep .q-notification__message {
//     color: var(--q-text-bagde-color) !important;
//   }
// }
.my-badge-class {
  border-radius: 2px !important;
  height: 45px;
  width: 345px;
  font-size: 16px !important;
  font-family: Manrope;

  flex-direction: column;
  justify-content: center;
}
.title-font {
  font-family: Prata;
  color: #f1f1f1;

  text-align: center;

  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.body-font {
  font-family: Manrope !important;
  font-size: 17px;
}
.custom-notification {
  height: 45px;
  width: 345px;
  font-size: 16px;
  background-color: var(--q-bagde-color) !important;
  color: var(--q-text-bagde-color) !important;
  border-radius: var(--q-border-radius) !important;
  margin: 0 !important;

  .q-notification__message {
    font-size: 16px;
    font-family: Manrope;
    padding: 0;
  }

  .q-notification__wrapper {
    display: flex;
    align-items: baseline;
  }
}

.help-view {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #082136;
}

.help-img {
  height: 144px;
  max-width: 182px;
  margin-top: 52px;
}
</style>
