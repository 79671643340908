<template>
  <section id="scroll-list" class="pl-us-menu-list">
    <div v-if="!noOneProduct" class="pl-us-container-lists">
      <div
        v-for="category in categoriesSpecial"
        :key="category.id"
        class="container-bestseller"
        :style="{ background: getBranchConfig.html_color_palette?.Secondary }"
      >
        <div class="pl-us-category-header">
          <h2
            :id="category.ref"
            class="pl-us-category-title "
            style="font-family:Prata ;"
            :style="{
              ...getStyles?.h2,
              color: getStyles.h2?.color,
              marginBottom: category.description.length === 0 ? '16px' : '0px',
            }"
          >
            {{ category.name }}
          </h2>
        </div>
        <div class="best-seller">
          <div v-for="item in category.products" :key="item.id">
            <BestSellerProducts
              :openModal="toggleModalProduct"
              :food="item"
              :categoryAvailability="category.is_available"
            ></BestSellerProducts>
          </div>
        </div>
      </div>

      <div
        v-for="category in categoriesAvailable"
        :key="category.id"
        class="container-categories-available"
        :style="{ background: getBranchConfig.html_color_palette?.Secondary }"
      >
        <div class="pl-us-category-header">
          <h2
            :id="category.ref"
            class="pl-us-category-title "
            style="font-family:Prata; margin-top: 24px "
            :style="{
              ...getStyles?.h2,
              color: getStyles.h2?.color,
              marginBottom: category.description.length === 0 ? '16px' : '0px',
            }"
          >
            {{ category.name }}
          </h2>
          <q-chip
            v-if="!category.is_available"
            style="background-color: #082136 !important; "
            class="q-mx-none body-font"
            text-color="white"
            :label="$t('pages.productitem.noDisponible')"
            :style="{ borderRadius: `${getBranchConfig?.radius}px` }"
          ></q-chip>
        </div>
        <q-item-section class="mp-q-item-section" side> </q-item-section>
        <div
          v-if="
            category.description.length > 0 && category.products.length != 0
          "
          class=" text-left "
          style="margin-bottom: 16px;"
          :style="{
            ...getStyles?.p,
            color: getStyles.p?.color,
          }"
        >
          {{ category.description }}
        </div>
        <div v-if="category.products.length > 0" class="pl-us-list-by-category">
          <div v-for="item in category.products" :key="item.id">
            <ProductItem
              :openModal="toggleModalProduct"
              :food="item"
              :categoryAvailability="category.is_available"
            ></ProductItem>
          </div>
        </div>
      </div>
      <p
        style="padding-top: 24px; padding-bottom: 8px; margin: 0; text-align: left; padding-left: 20px; padding-right: 20px;"
        :style="{
          ...getStyles?.p,
          color: getStyles.p?.color,
          background: getBranchConfig.html_color_palette?.Secondary,
        }"
      >
        *Consuming raw or undercooked meats, poultry, seafood, shellfish, or
        eggs may increase your risk of foodborne illness, especially if you have
        certain medical conditions.
      </p>
      <div
        v-for="category in categoriesUnavailable"
        :key="category.id"
        class="container-categories-unavailable"
        :style="{ background: getBranchConfig.html_color_palette?.Secondary }"
      >
        <q-expansion-item
          :id="category.ref"
          class="pl-us-list-by-category item"
          style=" margin-left: 0px; "
          :style="{
            borderBottom: `0.2px solid ${getBranchConfig.html_color_palette?.Stroke}`,
          }"
        >
          <template v-slot:header>
            <q-item-section class="mp-q-item-section">
              <div class="col items-center">
                <h2
                  class="col"
                  :style="{
                    ...getStyles?.h2,
                    color: getStyles.h2?.color,
                    marginBottom:
                      category.description.length === 0 ? '16px' : '0px',
                  }"
                  style="font-family: Prata; color: white; text-align: left;   margin: 0"
                >
                  {{ category.name }}
                </h2>
                <!-- <p
                    class="q-ma-none"
                    :style="{ ...getStyles?.p, color: getStyles.p?.color }"
                    style="font-family: Manrope; color: white; text-align: left; font-size:17px; margin-top:11px"
                  >
                    {{ category.description }}
                  </p> -->
              </div>
            </q-item-section>

            <q-item-section
              class="pl-us-mp-q-item-section body-font section-chip"
              style="justify-content: normal; padding: 0px !important; "
              side
            >
              <q-chip
                class="q-mx-none body-font"
                style="padding: 8px;  "
                :label="$t('pages.productitem.noDisponible')"
                :style="{
                  ...getStyles?.p,
                  color: getStyles.p?.colorSecondary,
                  background: getBranchConfig.html_color_palette?.Accent,
                  borderRadius: `${getBranchConfig?.radius}px`,
                }"
              ></q-chip>
            </q-item-section>
          </template>
          <q-expansion-item-content>
            <div
              v-for="item in category.products"
              :key="item.id"
              class="product-item-wrapper"
            >
              <ProductItem
                :openModal="toggleModalProduct"
                :food="item"
                :categoryAvailability="category.is_available"
              ></ProductItem>
            </div>
          </q-expansion-item-content>
        </q-expansion-item>
      </div>
      <ModalProduct
        v-model="productModal"
        :currentProduct="currentProduct"
        :close="toggleModalProduct"
        :categoryAvailability="currentCategoryAvailability"
      />
      <div
        class="pl-us-offset"
        :style="{ background: getBranchConfig.html_color_palette?.Secondary }"
      ></div>
    </div>
    <div v-else>
      <p>{{ $t("pages.productlist.notFound") }}</p>
    </div>
  </section>
</template>
<script>
import { defineAsyncComponent } from "vue"
import { mapGetters } from "vuex"
import ProductItem from "./ProductItem.vue"
import BestSellerProducts from "./BestSellerProducts.vue"
export default {
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BestSellerProducts,
    ProductItem,
    ModalProduct: defineAsyncComponent(() =>
      import("../modals/ModalProduct.vue")
    ),
  },
  data() {
    return {
      productModal: false,
      onlyReadModal: false,
      maximizedToggle: true,
      currentProduct: null,
      currentCategoryAvailability: null,
      categoriesSpecial: [],
      categoriesAvailable: [],
      categoriesUnavailable: [],
    }
  },
  watch: {
    categories() {
      this.categoriesSpecial = []
      this.categoriesAvailable = []
      this.categoriesUnavailable = []

      this.categories.forEach(category => {
        if (category.specials_category && category.is_available) {
          this.categoriesSpecial.push(category)
        } else if (category.is_available) {
          this.categoriesAvailable.push(category)
        } else if (!category.is_available) {
          this.categoriesUnavailable.push(category)
        }
      })
    },
  },

  computed: {
    ...mapGetters("socket", {
      phoneData: "getPhoneData",
    }),
    ...mapGetters({
      categories: "getFilteredCategories",
    }),
    ...mapGetters(["getBranchInfo", "getStyles", "getBranchConfig"]),
    noOneProduct() {
      let products = 0
      for (let index = 0; index < this.categories.length; index++) {
        const element = this.categories[index]
        products = products + element.products.length
      }
      return products == 0
    },
  },
  methods: {
    changeColor(newColor, variable) {
      // Cambia el color de la variable CSS
      document.documentElement.style.setProperty(variable, newColor)
    },
    toggleModalProduct(product) {
      const body = {
        product: product.id,
        client_id: this.phoneData.phone || null,
        sucursal: this.getBranchInfo[0].id,
      }
      this.$store.dispatch("mostLessProducts", body).then(response => {
        response
      })

      // Encontrar la categoría del producto
      const category = this.findProductCategory(product.id)

      this.currentCategoryAvailability = category ? category.is_available : null

      if (this.onlyRead) {
        this.onlyReadModal = !this.onlyReadModal
      } else {
        this.productModal = !this.productModal
      }
      this.currentProduct = product

      this.$store.commit("setFoodConfig", product)
    },

    findProductCategory(productId) {
      const allCategories = [
        ...this.categoriesSpecial,
        ...this.categoriesAvailable,
        ...this.categoriesUnavailable,
      ]
      return allCategories.find(category =>
        category.products.some(product => product.id === productId)
      )
    },
  },
}
</script>
<style lang="scss">
.container-categories-available,
.container-categories-unavailable {
  padding-right: 20px;
  padding-left: 20px;
}

.container-bestseller {
  padding-right: 0px;
  padding-left: 20px;
  // padding-top: 24px;
  max-width: 410px;
  width: 100%;
}
.best-seller {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 12px;
  padding-right: 20px;
  overflow-y: hidden;
}
.pl-us-list-by-category {
  .q-expansion-item__toggle-icon {
    color: var(--q-arrow-color);
  }

  .q-item__section--side {
    padding-left: 8px !important;
    justify-content: center;
  }
}
.pl-us-list-by-category .q-item__section--side {
  color: white;
  // padding-left: 8px;
}

.product-item-wrapper {
  margin-bottom: 21px;
}
// .q-expansion-item__container .q-item__section {
//   // padding-left: 1px;
//   justify-content: normal;
// }
.q-item__section .q-chip {
  border-radius: 0px;
  height: 30px;
  width: 116px;
}
.section-chip .q-item__section .q-chip {
  padding-top: 0px !important;
  border-radius: 0px;
}
.pl-us-mp-q-item-section {
  justify-content: normal !important;
}
.pl-us-list-by-category .q-expansion-item__container .q-item {
  padding-left: 0px !important;
  padding-right: 0px !important;

  padding: 16px 16px;
}
.pl-us-category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pl-us-menu-list {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 60px; */
  padding-bottom: 80px;
  padding-top: 24px;
  // padding-right: 30px;

  /* overflow-y: auto; */
  /* padding-bottom: 50px; */
  scroll-behavior: smooth;
}
.pl-us-container-lists {
  width: 100%;
  max-height: calc(100vh - 305px);
  overflow: visible;
}

.pl-us-category-title {
  text-align: start;
  // margin-bottom: 16px;
}
.pl-us-list-by-category {
  display: grid;
  row-gap: 21px;
}
.pl-us-category-description {
  font-size: 14px;
  color: white;
}
/* p {
  margin-top: 20px;
  width: 280px;
} */
.pl-us-offset {
  height: 78px;
}
@media screen and (min-width: 767px) {
  .pl-us-list-food {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 26px;
  }
}

@media screen and (max-width: 430px) {
  .container-bestseller {
    max-width: 100vw;
    width: 100%;
  }
}
</style>
